<script lang="ts" setup>
const { t } = useT();
const route = useRoute();
const isGuest = useIsGuest();
const { feedback } = route.query;
const { seoData } = useHomePage();
const { data: appInitData } = useAppInitData();
const [expanded, toggleExpanded] = useToggle();
const { scrollToContent, content } = useScrollTo();
const { handleSupportButtonClick } = useSupportChat();

const currentYear = new Date().getFullYear();

const hasDeposits = computed(() => appInitData.value?.hasDeposits);
const isShowAffiliate = computed(() => !isGuest.value && appInitData.value?.isAffiliate);

const filterFooterLinks = (slug: string) => {
	const rules = [
		{ slugs: ["alternative-method-of-entry"], condition: () => isGuest.value },
		{ slugs: ["affiliate-agreement", "partners-rules"], condition: () => !isShowAffiliate.value },
		{ slugs: ["promotional-games-rules", "refund-policy"], condition: () => !hasDeposits.value }
	];

	return !rules.some((rule) => rule.slugs.includes(slug) && rule.condition());
};

const termsLinks = computed(() =>
	appInitData.value?.footer?.menu
		?.find((item) => item?.title === "second_column")
		?.items?.filter((link) => link?.slug && filterFooterLinks(link?.slug))
);

const trustPilotRate = computed<number | string>(() => Number(appInitData.value?.trustpilot?.rate).toFixed(1));

const backgroundPercent = computed(() => {
	const decimalRate = Number(trustPilotRate.value.toString().split(".")[1]);
	return `${trustPilotRate.value ? decimalRate * 10 : 45}%`;
});

const footerSeoText = computed(
	() =>
		({
			title: seoData.value?.seoH1,
			text: seoData.value?.seoText
		}) || {}
);

const getStarClass = (number: number) => {
	if (trustPilotRate.value && Number(trustPilotRate.value) >= number) {
		return "active";
	}
	if (trustPilotRate.value && Number(trustPilotRate.value) > number - 1 && Number(trustPilotRate.value) < number) {
		return "partial";
	}

	return "";
};

const handleSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "footer",
		button_name: "support"
	});
	handleSupportButtonClick();
};

onMounted(() => {
	if (feedback) {
		setTimeout(() => {
			scrollToContent(-100);
		}, 50);
	}
});
</script>

<template>
	<footer ref="content" class="footer">
		<div class="wrapper">
			<div class="info">
				<div class="box-description">
					<div class="about" data-tid="footer-about">
						<AText variant="toledo" :modifiers="['semibold']" class="text-cannes">{{ t("About FortuneWheelz") }}</AText>
						<div class="about-text">
							<AText>{{
								t(
									"NO PURCHASE IS NECESSARY to enter free game promotion (“promotional games”). PROMOTIONAL GAMES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see Terms of Use."
								)
							}}</AText>
							<AText>{{
								t(
									"Fortune Wheelz Social Gaming Platform is a play-for-fun website intended for amusement purposes only. NoLimitCoins Social Gaming Platform does not offer “real-money gambling” or opportunity to win real money based on a gameplay."
								)
							}}</AText>
							<AText>{{
								t(
									"Fortune Wheelz Social Gaming Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry."
								)
							}}</AText>
						</div>
					</div>
					<div class="info bottom">
						<div v-if="!!footerSeoText" class="accordion">
							<ACollapse :expanded="expanded">
								<template #title>
									<div
										:class="['accordion-header', { expanded: expanded }]"
										data-tid="footer-accordion"
										@click="toggleExpanded()"
									>
										<AText variant="toledo" :modifiers="['semibold']">
											{{ footerSeoText?.title }}
										</AText>
										<ASvg name="24/arrow-down" :class="['accordion-icon', { expanded: expanded }]" />
									</div>
								</template>
								<template #expanded>
									<div class="accordion-content" v-html="footerSeoText?.text" />
								</template>
							</ACollapse>
						</div>
					</div>
				</div>
				<div class="box-option" data-tid="footer-feedback">
					<ClientOnly>
						<OFeedback />
					</ClientOnly>

					<div class="contacts">
						<div class="help">
							<AText class="text-cannes" :modifiers="['semibold']">{{ t("Need help?") }}</AText>
							<div class="help-content">
								<div class="help-item" @click="handleSupportClick">
									<ASvg name="24/support" class="help-icon" />
									<AText :modifiers="['underline']" class="help-text" data-tid="footer-support">{{
										t("Online support")
									}}</AText>
								</div>
								<div class="help-item">
									<ASvg name="24/email" class="help-icon" />
									<a href="mailto:support@fortunewheelz.com" data-tid="footer-support-email">
										<AText :modifiers="['underline']" class="help-text">support@fortunewheelz.com</AText>
									</a>
								</div>
							</div>
						</div>
						<ClientOnly>
							<div v-if="appInitData?.socialNetworks?.length" class="socials">
								<NuxtLink
									v-for="(item, index) in appInitData?.socialNetworks"
									:key="index"
									:to="item?.url"
									:data-tid="`footer-social-${item.id}`"
									target="_blank"
								>
									<div v-html="item?.icon" />
								</NuxtLink>
							</div>
						</ClientOnly>

						<NuxtLink
							class="icon-trustpilot"
							data-tid="footer-trustpilot"
							to="https://www.trustpilot.com/review/fortunewheelz.com"
							external
							target="_blank"
						>
							<NuxtImg
								src="/nuxt-img/footer/trustpilot.png"
								alt="trustpilot"
								width="180"
								height="45"
								format="webp"
								loading="lazy"
							/>
							<div class="stars">
								<span v-for="number in 5" :key="number" :class="getStarClass(number)">
									<ASvg name="star" />
								</span>
							</div>
							<AText variant="topeka" class="text">
								<i18n-t keypath="TrustScore {rate} {divider} {count}">
									<template #rate>
										<b>{{ trustPilotRate }}</b>
									</template>
									<template #divider>
										<span class="divider" />
									</template>
									<template #count>
										<b>{{ appInitData?.trustpilot?.reviewsCount }} {{ t("reviews") }}</b>
									</template>
								</i18n-t>
							</AText>
						</NuxtLink>
					</div>
				</div>
			</div>

			<div class="rules">
				<NuxtImg
					src="nuxt-img/logo-footer.png"
					format="webp"
					width="96"
					height="64"
					class="logo"
					alt="logo"
					loading="lazy"
				/>
				<ClientOnly>
					<div v-if="termsLinks?.length" class="links">
						<NuxtLink
							v-for="(link, index) in termsLinks"
							:key="`${link?.title}-keyName`"
							:data-tid="`footer-terms-${index}`"
							:to="`/page${link?.url}`"
						>
							<AText variant="topeka" :modifiers="['underline', 'link']">{{ link?.title }}</AText>
						</NuxtLink>
					</div>
				</ClientOnly>
			</div>
			<AText variant="topeka" :modifiers="['center']" as="div" class="copyright" data-tid="footer-copyright">{{
				t("© Fortune Wheelz Social Gaming Platform", { msg: currentYear })
			}}</AText>
		</div>
	</footer>
</template>

<style scoped lang="scss">
.flex-column {
	display: flex;
	flex-direction: column;
}

.footer {
	@include media-breakpoint-up(lg) {
		margin-left: calc(var(--o-sidebar-width) + 24px);
		width: calc(100% - var(--o-sidebar-width) - 24px);
	}

	.help-icon {
		font-size: 24px;
	}

	.wrapper {
		@extend .flex-column;
		width: 100%;
		max-width: 1136px;
		margin: 0 auto;
		gap: 24px;
		background: var(--craiova);
		padding: 24px 16px;

		@include media-breakpoint-up(lg) {
			padding: 32px;
			padding-bottom: 24px;
		}
	}

	.contacts {
		@extend .flex-column;
		gap: 24px;

		@include media-breakpoint-up(lg) {
			min-width: 328px;
		}
	}

	.info {
		@extend .flex-column;
		gap: 24px;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			align-items: flex-start;

			&.bottom {
				order: 2;
				margin-top: 8px;
			}
		}

		@include media-breakpoint-down(lg) {
			flex-direction: column-reverse;
		}
	}

	.about {
		@extend .flex-column;
		gap: 16px;
		padding-top: 24px;
		border-top: 1px solid var(--changchun);
		color: var(--cocoa);

		&.bottom {
			border-top: 0;
			padding: 0;
		}

		&.desk {
			display: none;
		}

		@include media-breakpoint-up(lg) {
			border-top: 0;
			padding: 0;
			&.mob {
				display: none;
			}

			&.desk {
				display: flex;
			}

			&.bottom {
				width: 328px;
			}
		}
	}
}

.about-text {
	@extend .flex-column;
	gap: 12px;
}

.help {
	@extend .flex-column;
	justify-content: center;
	gap: 16px;
	color: var(--caracas);
}
.help-item {
	display: flex;
	align-items: center;
	gap: 8px;
	color: var(--caracas);
	cursor: pointer;
}

.help-content {
	@extend .flex-column;
	gap: 16px;
}

.help-text {
	color: var(--caracas);
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}

.socials {
	display: flex;
	gap: 8px;
	font-size: 32px;
	color: var(--cocoa);
}

.trustpilot {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--cocoa);
	font-size: 32px;

	@include media-breakpoint-up(lg) {
		justify-content: flex-start;
		order: 1;
		gap: 32px;
	}
}

.box-description {
	@extend .flex-column;
	gap: 24px;

	@include media-breakpoint-up(lg) {
		gap: 16px;
	}
}

.lg-hidden {
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.accordion {
	padding: 16px 0;
	border-bottom: 1px solid var(--coyah);

	@include media-breakpoint-up(lg) {
		flex: 1;
	}
}

.accordion-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	line-height: 24px;

	&.expanded {
		color: var(--chicago);
	}
}
.accordion-icon {
	cursor: pointer;
	font-size: 24px;
	transition: transform 0.3s ease;

	&.expanded {
		transform: rotate(-180deg);
	}
}

.accordion-content {
	padding-top: 16px;
	font-size: 12px;
	line-height: 14px;
	&:deep() {
		h2,
		h3 {
			margin-bottom: 12px;
		}
		p {
			margin-bottom: 8px;
		}

		ul {
			padding-left: 10px;
			list-style-type: disc;
			> li {
				margin-bottom: 12px;
			}
		}
		a {
			color: var(--primary-500);
		}
	}
}

.rules,
.links {
	@extend .flex-column;

	gap: 12px;
}

.rules {
	padding-bottom: 24px;
	border-bottom: 1px solid var(--coyah);

	@include media-breakpoint-up(lg) {
		padding: 24px 0;
		flex-direction: row;
		justify-content: space-between;
		border-top: 1px solid var(--coyah);
	}
}

.links {
	@include media-breakpoint-up(lg) {
		flex-direction: row;
		gap: 32px;
	}
}

.link {
	color: var(--cocoa);

	&:hover {
		text-decoration: none;
	}
}

.logo {
	height: 32px;
	width: 48px;
}

.icon-trustpilot {
	gap: 4px;
	display: flex;
	margin-top: -12px;
	align-items: center;
	flex-direction: column;
	align-self: flex-start;

	.text {
		gap: 4px;
		display: flex;
		align-items: center;
		color: var(--colon);
	}

	.divider {
		width: 1px;
		height: 9px;
		background: var(--colon);
	}

	img {
		width: 90px;
		height: 22px;
	}

	.stars {
		gap: 4px;
		display: flex;

		> span {
			height: 24px;
			width: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #dddee6;

			&.active {
				background: var(--chemnitz);
			}

			&.partial {
				background: linear-gradient(
					90deg,
					var(--chemnitz) v-bind(backgroundPercent),
					#dddee6 v-bind(backgroundPercent)
				);
			}
		}
	}
}
</style>
